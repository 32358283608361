import axios from "axios"
import moment from "moment"
import React from "react"
import { navigate } from "gatsby"

import bookingform from "../../queries/bookingform"
import "../../styles/bookingform.css"

class BookingForm extends React.Component {
  GRAPHQL_API = process.env.GATSBY_PIH_URL

  /**
   *Creates an instance of BookingForm.
   * @param {*} props
   * @memberof BookingForm
   */
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.fetchUpdateData = this.fetchUpdateData.bind(this)
  }

  /**
   * Once the component is rendered start fetching the tour data
   * @memberof BookingForm
   */
  componentDidMount() {
    console.log("BookingForm mounted")
    this.setState({
      pickUp: null,
      dropOff: null,
      showPD: false,
      tourAvailable: false,
      options: [],
      tourDate:
        (typeof window !== "undefined" &&
          window.localStorage.getItem("tourDate")) ||
        moment().format("YYYY-MM-DD"),
    })
    typeof window !== "undefined" &&
      window.localStorage.setItem("tourCode", this.props.tourCode)
    this.fetchUpdateData()
  }

  fetchUpdateData() {
    var date = (typeof window !== "undefined" &&
              window.localStorage.getItem("tourDate")) ||
              moment().format("YYYY-MM-DD")
    console.log("Fetching fresh data for date", date)

    // Hide tour data while data is refreshing.
    this.setState({tourAvailable: false})
    axios
      .post(
        this.GRAPHQL_API,
        {
          query: bookingform.getBookingForm(
            76, // TODO: Pull agent code from config / env var.
            this.props.tourCode,
            date
          ),
        },
        {}
      )
      .then(response => {
        console.log("BookingForm response from query", response)

        // TODO: Log error if getPickups or getDropoffs is null/empty
        if (!response.data.errors && response.data.data) {
          // Sort pickup and dropoff data by name of location
          response.data.data.getPickups = response.data.data.getPickups.sort(
            (a, b) => a.name > b.name
          )
          response.data.data.getDropoffs = response.data.data.getDropoffs.sort(
            (a, b) => a.name > b.name
          )

          this.setState(response.data.data)
          this.populateDefaultPassengers()
          this.togglePickupDropOff(false)
          this.setState({ tourAvailable: true })
        } else {
          // Based on the response we will render the inputs
          // If response does not contain the tour pick up data i.e tour is not available for that day
          this.setState({ tourAvailable: false })
        }
      })
  }

  changeDate(event) {
    this.setState({ tourDate: event.target.value })
    typeof window !== "undefined" &&
      window.localStorage.setItem("tourDate", event.target.value)
    this.fetchUpdateData()
    // window.location.reload()
  }

  changePassengers(event, priceDetails) {
    this.setState({ [priceDetails.passengerType]: event.target.value })
  }

  selectPickUp(event) {
    this.setState({ pickUp: event.target.value })
  }

  populateDefaultPassengers() {
    this.state.getTourPricing.forEach(price => {
      this.setState({ [price.passengerType]: 0 })
    })
  }

  selectDropOff(event) {
    this.setState({ dropOff: event.target.value })
  }

  /**
   * This method handles toggle on/off pick up/drop off detail/")
   * When toggle is either switched on/off, this method intialises the default pick up and drop off location
   * @param {boolean} value - boolean value that represents the pickup/dropoff checkbox status
   */
  togglePickupDropOff(value) {
    const defaultPickUp = this.state?.getPickups[0]?.id
    const defaultDropOff = this.state?.getDropoffs[0]?.id
    this.setState({ pickUp: defaultPickUp, dropOff: defaultDropOff })
    this.setState({ showPD: value })
    // console.log("cart.js pickup", this.state.showPD)
  }

  /**
   * This method toggles on/off option details
   * When the toggle is on, this method initialises all passengers to 1
   * WHen the toggle is off, this method removes the option from the state
   * @param {*} event
   * @param {*} option
   * @memberof BookingForm
   */
  toggleOptions(event, option) {
    let options = this.state.options
    if (event.target.checked) {
      let people = {}
      option.prices.forEach(price => {
        people[price.passengerType] = 0
      })
      options.push({
        id: option.id,
        people,
      })
    } else {
      const index = options.findIndex(opt => option.id === opt.id)
      if (index !== -1) {
        options.splice(index, 1)
      }
    }
    this.setState({ options: options })
    // console.log(this.state.options)
  }

  /**
   * This method handles toggle on/off on option passenger details i.e no of passengers of given passenger type
   * @param {Objebct} event - DOM checkbox element
   * @param {Objebct} priceDetails - price details of the option
   * @param {Objebct} option - option cart item
   * @memberof BookingForm
   */
  toggleOptionPriceDetails(event, priceDetails, option) {
    let options = this.state.options
    const index = options.findIndex(opt => option.id === opt.id)
    options[index].people[priceDetails.passengerType] = event.target.value
  }

  /**
   * This method is called when the user clicks on book now button
   * When the submit form is clicked cart is initialised and tour is added to the cart
   * @memberof BookingForm
   */
  submitForm() {
    const query = bookingform.initialiseCart(76) // TODO: Pull agent ID from env var
    axios.post(this.GRAPHQL_API, { query }, {}).then(response => {
      // If the cart is initialised successfully then add the tour to the cart
      if (response.data.data) {
        this.addTour(
          response.data.data.initialiseCart.cart.bookingReference,
          response.data.data.initialiseCart.token,
          response.data.data.initialiseCart.cart.expiresAt
        )
      }
    })
  }

  /**
   * This method is used to add the selected tour to the cart
   *
   * @param {*} bookingRef
   * @param {*} token
   * @memberof BookingForm
   */
  addTour(bookingRef, token, expiresAt) {
    // Set the local storage for later use in other components
    typeof window !== "undefined" &&
      window.localStorage.setItem("bookingRef", bookingRef)
    typeof window !== "undefined" && window.localStorage.setItem("token", token)
    typeof window !== "undefined" &&
      window.localStorage.setItem("expiresAt", expiresAt)

    // Set query and headers
    const query = bookingform.addTour(
      bookingRef,
      this.props.tourCode,
      this.state,
      this.state.options.length !== 0 ? this.state.options : [], // FIXME Must send at least one option
      this.state.pickUp,
      this.state.dropOff
    )
    const headers = { Authorization: `Bearer ${token}` }

    // Add tour the cart
    axios.post(this.GRAPHQL_API, { query }, { headers }).then(response => {
      if (response.data.data) {
        // TODO: Check response.data.errors before redirecting.
        if (response.data.errors) {
          // TODO: User facing error logging
          console.log("errors", response.data.errors)
          return
        }
        navigate("/cart/")
      }
    })
  }

  render() {
    return (
      <div className="six columns">
        <div id="tour-sidebar">
          <h3>Book your tour</h3>
          <div className="TourBook">
            <form className="TourBookingForm">
              <div className="TourForm">
                <div className="TravelDate clearfix">
                  <label htmlFor="date">Select travel date:</label>
                  <input
                    type="date"
                    className="date text"
                    placeholder="dd/mm/yyyy"
                    required="required"
                    defaultValue={this.state?.tourDate}
                    onChange={event => this.changeDate(event)}
                  />
                </div>
                {this.state?.tourAvailable ? (
                  <div className="Pricing clearfix">
                    {this.state?.getTourPricing?.map((el, index) => (
                      <div
                        className="PriceComp TourPrice AD clearfix"
                        key={index}
                      >
                        <label htmlFor="price">
                          <span className="price">
                            {" "}
                            {el.passengerType[0] +
                              el.passengerType.substring(1).toLowerCase()}
                            :{" "}
                          </span>{" "}
                          ${el.price.toFixed(2)}{" "}
                        </label>
                        <select
                          className="h5dropdown"
                          onBlur={event => this.changePassengers(event, el)}
                        >
                          {[...Array(11)].map((el, i) => (
                            <option value={i} key={i}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
                {this.state?.tourAvailable ? (
                  <div className="TourArrDep">
                    <div className="Departs">
                      <label htmlFor="departs" className="StopDef">
                        Departs:
                      </label>
                      <span>
                        {" "}
                        {this.state?.getPickups
                          ? this.state?.getPickups[0]?.time +
                            " - " +
                            this.state?.getPickups[0]?.description
                          : ""}
                      </span>
                    </div>
                    <div className="Returns">
                      <label htmlFor="returns" className="StopDef">
                        Returns:
                      </label>
                      <span>
                        {" "}
                        {this.state?.getDropoffs
                          ? this.state?.getDropoffs[0]?.time +
                            " - " +
                            this.state?.getDropoffs[0]?.description
                          : ""}
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="ExtrasOpt" id="ExtraOptContainer">
                  {this.state?.getOptions
                    ?.filter(opt => opt.type === "BOOKING_REQUIRED")
                    ?.map((option, index) => (
                      <div
                        className="extraContainer Flightseeing clearfix"
                        key={index}
                      >
                        <label
                          htmlFor="extras"
                          className="ExtraCatCB Flightseeing"
                        >
                          {" "}
                          Add{" "}
                          {option.category ? (
                            option.category
                          ) : (
                            <span>{option.title}</span>
                          )}
                        </label>
                        <input
                          className="SelExtraOptCat"
                          type="checkbox"
                          onChange={event => this.toggleOptions(event, option)}
                        />
                        {this.state.options.findIndex(
                          opt => opt.id === option.id
                        ) !== -1 ? (
                          <div className="Extra Flightseeing selExtraCategory clearfix">
                            <input className="SelExtraOpt" type="checkbox" />
                            <label
                              htmlFor="extras"
                              className="Extra Flightseeing"
                            >
                              {option.title}
                            </label>
                            {option.prices.length !== 0 && (
                              <div className="Pricing clearfix">
                                <div
                                  className="PriceComp ExtraPrice Adult AD"
                                  data-disc="AD"
                                >
                                  {option.prices.map((priceDetails, index) => (
                                    <div key={index}>
                                      <label htmlFor="price">
                                        {" "}
                                        <span className="price">
                                          {" "}
                                          {priceDetails.passengerType[0] +
                                            priceDetails.passengerType
                                              .substring(1)
                                              .toLowerCase()}
                                          :{" "}
                                        </span>{" "}
                                        <br></br> ${priceDetails.price}
                                      </label>
                                      <select
                                        className="h5dropdown"
                                        onBlur={event =>
                                          this.toggleOptionPriceDetails(
                                            event,
                                            priceDetails,
                                            option
                                          )
                                        }
                                      >
                                        {[...Array(11)].map((el, i) => (
                                          <option key={i}>{i}</option>
                                        ))}
                                      </select>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                </div>
                <br className="clear" />
                <div className="PickupCheckcontainer">
                  {this.state?.tourAvailable ? (
                    <div className="pickupcheck clearfix">
                      <label htmlFor="pickupdropff">
                        Would you like a complimentary hotel Pickup or Drop-off?
                      </label>
                      <input
                        className="HotelToggle checkbox_check"
                        type="checkbox"
                        onClick={event =>
                          this.togglePickupDropOff(event.target.checked)
                        }
                      />
                      {!this.state?.showPD ? (
                        ""
                      ) : (
                        <div className="pickupcontent" id="HotelContainer">
                          <label className="StopHtl" htmlFor="pickup">
                            Select Hotel Pickup Below
                          </label>
                          <select
                            id="A76L_PUHtl"
                            name="PickUp"
                            onBlur={event => this.selectPickUp(event)}
                          >
                            {this.state?.getPickups?.map((el, index) => (
                              <option value={el.id} key={el.code}>
                                {" "}
                                {el.description} {el.time}
                              </option>
                            ))}
                          </select>
                          <label className="StopHtl" htmlFor="dropoff">
                            Select Hotel Drop-Off Below
                          </label>
                          <select
                            id="A76L_DOHtl"
                            name="DropOff"
                            onBlur={event => this.selectDropOff(event)}
                          >
                            {this.state?.getDropoffs?.map(el => (
                              <option value={el.id} key={el.code}>
                                {" "}
                                {el.description} {el.time}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {this.state?.tourAvailable ? (
                  <div className="Actions clearfix">
                    <input
                      type="button"
                      name="BookTour"
                      defaultValue="Book now"
                      alt="Click here to book this tour"
                      id="Form_Form_action_process"
                      onClick={this.submitForm}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default BookingForm
