import React from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import BookingForm from "../../../../components/dynamic/BookingForm"

function MilfordSoundTourChristchurchQueenstown(props) {
  return (
    <>
      <SEO title="Milford Sound + Christchurch to Queenstown Bus - awesomeNZ" />
      <Layout
        layoutType="typography TourPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/sliders/milford-sound-day-tour-cruise.jpg")}
                alt=""
                class="first image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/coach.jpg")}
                alt=""
                class="last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={true}
        hasSliderBlurLarge={true}
        content={
          <>
            {/* FIXME: Custom booking form required */}
            <BookingForm tourCode="GREENSTONE" />

            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Milford Sound Tour + Christchurch - Queenstown travel</h1>
                <h3>
                  Travel from Christchurch to Queenstown and experience the
                  beauty of Milford Sound
                </h3>
                <div class="highlights">
                  <ul>
                    <li>1 hour 45 minute Milford Sound cruise</li>
                    <li>Free lunch during your cruise</li>
                    <li>Travel from Christchurch to Queenstown</li>
                  </ul>
                </div>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    {/* Content from awesomeNZ tour page goes here */}
                    <p>
                      See some of New Zealand's most amazing natural scenery as
                      you travel from Christchurch to Queenstown, and spend a
                      day exploring one of the most beautiful places on Earth on
                      a day trip to <strong>Milford Sound</strong> with this
                      great combo deal.
                    </p>
                    <p>
                      On your Milford Sound day tour you'll set off from{" "}
                      <strong>Queenstown</strong> and travel through glacier
                      valleys and mountain ranges to{" "}
                      <strong>Fiordland National Park</strong>, enjoying the
                      view from your glass-roof touring coach as your driver
                      guide tells you about the history of this special part of
                      the world.
                      <br />
                      <br />
                      Stop for a bite to eat at Te Anau, before driving along
                      the shores of Lake Te Anau and into the{" "}
                      <strong>Eglinton Valley</strong>, home to ancient alpine
                      forests, massive waterfalls and the famous{" "}
                      <strong>Mirror Lakes</strong>. Pass through the 1.2km long{" "}
                      <strong>Homer Tunnel</strong> (drilled through solid rock)
                      and descend into <strong>Milford Sound,</strong> where
                      you'll board a cruise boat and tuck in to a{" "}
                      <strong>FREE lunch</strong>.<br />
                      <br />
                      Cruise along the fiord and take in the famous sights and
                      landmarks of Milford Sound, like the uniquely-shaped{" "}
                      <strong>Mitre Peak</strong>, cascading waterfalls and
                      glacier-scarred rock walls that rise from the sea. Keep an
                      eye out for <strong>marine life</strong> like dolphins,
                      seals and penguins during your cruise, and get up close to
                      the waterfalls as they tumble into the sound. After your
                      Milford Sound scenic cruise your guide will be waiting to
                      drive you back to Queenstown.
                      <br />
                      <br /> Your tour combo also includes a bus from
                      Christchurch to Queenstown, travelling through the flat
                      farmland of the Canterbury Plains, past breathtaking Lake
                      Tekapo, and through rural Otago to the adventure capital
                      of the world.
                    </p>
                    <h4>Christchurch - Queenstown bus details</h4>
                    <p>
                      Departs: 8:30am - Outside the Bus Interchange on Lichfield
                      St, near Colombo St.
                      <br /> Arrives: 4.30pm - Athol Street Carpark
                    </p>
                    <h4>Milford Sound tour details</h4>
                    <p>
                      Departs: 7:20 am - Athol St in the middle of the car park
                      <br /> Returns: 7:45 pm - Athol St in the middle of the
                      car park
                    </p>
                    <h3 class="label">
                      Booking your Milford Sound + Christchurch - Queenstown
                      tour Combo
                    </h3>
                    <p>
                      When you purchase this tour combo, you're pre-paying for a
                      pass which gives you access to these two tours at a
                      discounted rate. You can then use the pass to book each
                      tour on your chosen travel dates.{" "}
                      <strong>
                        Buying the pass does not automatically book you onto the
                        tours.
                      </strong>
                    </p>
                    <p>
                      At the checkout you'll be asked to choose an access code
                      (a bit like a password - it can be anything you like).
                      We'll send you a confirmation email with your pass number.
                    </p>
                    <p>
                      You can then{" "}
                      <Link title="Contact awesomeNZ" to="/contact-us">
                        contact us
                      </Link>{" "}
                      by phone or email to make your booking, or book the tours
                      online by visiting our partner website, InterCity. To book
                      online,{" "}
                      <a
                        title="Book your tour combo"
                        href="https://www.intercity.co.nz/passdetail"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        log in to the InterCity website
                      </a>{" "}
                      using your pass number and access code, then follow the
                      below instructions.&nbsp;
                    </p>
                    <p>
                      <strong>
                        To book your Milford Sound day tour and cruise:
                      </strong>
                    </p>
                    <ul>
                      <li>
                        Search for one-way travel from Queenstown to Queenstown
                        on your preferred travel date.
                      </li>
                      <li>
                        Click on the search results for full details and select
                        the Milford Sound day tour.
                      </li>
                      <li>
                        You'll see a link marked 'Optional extras are available
                        - please select any you wish to add'. Expand this and
                        select the Miford Sound cruise to add it to your
                        booking.&nbsp;
                        <strong>
                          Booking the coach portion of the tour does not
                          automatically book the cruise - you must add the
                          cruise to your cart as well.
                        </strong>
                      </li>
                      <li>
                        Once you've added the tour and cruise to your cart,
                        follow the rest of the steps to the checkout to confirm
                        your booking.
                      </li>
                    </ul>
                    <p>
                      <strong>
                        To book your Christchurch to Queenstown travel:
                      </strong>
                    </p>
                    <ul>
                      <li>
                        Search for one-way travel from Christchurch to
                        Queenstown on your preferred travel date. (You must
                        choose a different date from your Milford Sound tour and
                        cruise.)
                      </li>
                      <li>
                        Select the available service and add it to your cart,
                        then follow the rest of the steps to the checkout to
                        confirm your booking.
                      </li>
                    </ul>
                    <p>
                      Once you have booked the tours you will receive a
                      confirmation email showing the dates and times of your
                      tours, along with other useful information. If you have
                      not received a confirmation email please{" "}
                      <Link title="Contact awesomeNZ" to="/contact-us">
                        contact us
                      </Link>
                      .
                    </p>
                    {/* ... */}
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
              <div class="GSRelatedProducts clearfix">
                <h2 class="tour">Tours You May Also Like</h2>
                {/* ... */}
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-mt-cook-tour">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/thumbs/milford_chc_mt_cook.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Milford Sound + Mount Cook Tour</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$285.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-mt-cook-tour">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
                {/* ... */}
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default MilfordSoundTourChristchurchQueenstown
